import { Box, Container, Typography } from '@mui/material'

const App = () => (
  <Container fixed >
    <Box sx={{
      alignItems    : 'center',
      display       : 'flex',
      flexDirection : 'column',
      gap           : 10,
      height        : '100vh',
      justifyContent: 'center'
    }} >
      <Box sx={{ alignItems: 'center', display: 'flex', flex: 1 }}>
        <img alt='ochaya' src='/assets/logo.png' width={400} />
      </Box>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
        <Typography color='primary' sx={{ mb: 2 }} variant='h4'>Landing soon</Typography>
        <Typography color='primary' variant='h5'>Miraflores - Lima</Typography>
      </Box>
    </Box>
  </Container>
)

export default App
